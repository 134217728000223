<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
  import Index from './views/index.vue';

  export default {
    name: 'App',
    components: {
      Index,
    },
  };
</script>

<style>
  html,
  body,
  div,
  p,
  span,
  a {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-color: #fff;
    width: 100%;
    text-align: center;
  }

  a {
    color: #333;
    text-decoration: none;
  }
</style>
