<template>
  <div class="content">
    <div v-if="errMsg" class="one">
      <span class="label">提示</span>
      <span class="intro">{{ errMsg }}</span>
    </div>

    <template v-else>
      <div v-if="type == 0 && showTip" class="one">
        <span class="label">跳转地址</span>
        <span class="link">{{ url }}</span>
        <span class="intro">
          {{
            preview
              ? '当前预览暂时无法跳转到目标地址，请扫码以查看实际效果'
              : '即将访问上述网站，请注意保护好个人信息'
          }}
        </span>
        <span v-if="!preview" class="btn" @click="goTo">继续访问</span>
      </div>

      <div v-if="type == 1" class="list">
        <a v-for="(item, index) in urls" :key="index" href="#" @click.prevent="jump(item.url)">
          <div class="con">
            <span class="name">{{ item.name }}</span>
            <span class="link">{{ item.url }}</span>
          </div>
        </a>
      </div>
    </template>

    <div v-if="showTip" class="foot">
      <a href="https://www.jzx.com" class="web">机智熊二维码</a> 提供技术服务
    </div>
  </div>
</template>

<script>
  export default {
    name: 'index',
    data() {
      return {
        id: '',
        type: -1,
        url: '',
        urls: [],
        showTip: false,
        preview: false,
        errMsg: '',
        token: undefined,
        apiBase: 'https://api.jzx.com',
      };
    },
    mounted() {
      const params = this.getQueryParams();

      this.id = params.id || '';
      if (params.preview) this.preview = true;
      if (params.token) this.token = params.token;

      this.getData();
    },
    methods: {
      getQueryParams() {
        const params = {};
        const queryString = window.location.search.substring(1);
        const vars = queryString.split('&');

        vars.forEach((pair) => {
          const [key, value] = pair.split('=');
          params[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        return params;
      },
      goTo() {
        window.location.href = this.url;
      },
      jump(url) {
        if (this.showTip) {
          this.type = 0;
          this.url = url;
        } else {
          window.location.href = url;
        }
      },
      getRequestHeaders() {
        if (!this.token) {
          return {
            'Content-Type': 'application/json',
          };
        }

        return {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        };
      },
      async getData() {
        if (!this.id) return;

        const headers = this.getRequestHeaders();

        const response = await fetch(`${this.apiBase}/url/detail?id=${this.id}`, {
          method: 'GET',
          headers,
        });

        if (!response.ok) return;

        const { data, code, msg } = await response.json();

        if (code > 0) {
          this.showTip = true;
          this.errMsg = code === 2 ? '错误的地址' : msg;
        } else {
          const { detail, showTip } = data;
          const { type, url, urls } = detail;

          this.type = type;
          this.url = url;
          this.urls = urls;
          this.showTip = showTip || this.preview;

          if (type == 0 && !this.showTip) this.goTo();
        }

        if (this.showTip) {
          document.title = '机智熊';
        }

        if (this.preview) return;

        // 访问记录
        fetch(`${this.apiBase}/statistics/store_url_access?id=${this.id}`, {
          method: 'PUT',
          headers,
        });
      },
    },
  };
</script>

<style>
  .content {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .list {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
    padding: 0 20px;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 20px;
  }
  .list a {
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 12px 0;
  }
  .list a:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  .list a .con {
    flex-grow: 1;
    width: 0;
  }
  .list a .con .name {
    font-size: 14px;
    word-break: break-all;
    word-wrap: break-word;
    display: block;
  }
  .list a .con .link {
    font-size: 13px;
    word-break: break-all;
    word-wrap: break-word;
    color: #999;
  }
  .list a::after {
    content: '';
    width: 16px;
    height: 16px;
    display: inline-block;
    flex-shrink: 0;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAHNJREFUOE9jZKAQMFKon2FwGaDAwMDwgEgvwdUie2E+VHMiAUP2Qy0Cq0M2AGQqyBCQK3AZAtIMAo4wS9ADEZ8hGJrRXQAzFJshWDXjMgAkjmwIiI3ibOQwwpcOYIbg1IzPBcjewRu1gyslEpkIUZVR7AUAKgkSEUJ2Gl8AAAAASUVORK5CYII=');
    margin-left: 20px;
    opacity: 0.5;
    transform: rotate(-90deg);
  }
  .one {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }
  .one .label {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0;
  }
  .one .link {
    font-size: 15px;
    padding: 0 20px;
    word-break: break-all;
    word-wrap: break-word;
  }
  .one .intro {
    font-size: 13px;
    padding-top: 10px;
    color: #666;
  }
  .one .btn {
    cursor: pointer;
    font-size: 13px;
    width: 140px;
    line-height: 36px;
    background-color: #f3f3f3;
    color: #00bc66;
    border-radius: 4px;
    margin-top: auto;
    margin-bottom: 100px;
  }
  .foot {
    font-size: 12px;
    color: #999;
    line-height: 20px;
    margin-top: auto;
  }
  .foot a {
    color: #00bc66;
  }
</style>
